import image from "./images/iknowwhatitslike.jpg"

const Song = () => ({
    "name": "I KNOW WHAT ITS LIKE",
    "producedBy": "CRATER",
    "coverArt": image,
    "platforms": [
        {
            "name": "soundcloud",
            "url": "https://soundcloud.com/kayeandre/i-know-what-its-like"
        }
    ]
})

export default Song